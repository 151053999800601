
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { UserOutlined, LockOutlined } from '@ant-design/icons-vue'
import { Watch } from 'vue-property-decorator'
import { firebase } from '@/utils/firebase'
import * as firebaseui from 'firebaseui'

@Options({
  components: { UserOutlined, LockOutlined },
  directives: { maska },
})
export default class LoginRegisterMixin extends Vue {
  get isAuth() {
    return this.$store.getters.isAuth
  }

  @Watch('isAuth')
  onAuthChange() {
    if (this.isAuth) {
      this.$router.push({ name: 'home' })
    }
  }

  mounted() {
    const uiConfig = {
      signInSuccessUrl: '/login',
      signInFlow: 'popup',
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.GithubAuthProvider.PROVIDER_ID,
      ],
    }
    const ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebase.auth())
    ui.start('#firebaseui-auth-container', uiConfig)
  }
}
