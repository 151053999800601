<template>
  <div id="firebaseui-auth-container"></div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { maska } from 'maska'
import { UserOutlined, LockOutlined } from '@ant-design/icons-vue'

@Options({
  components: { UserOutlined, LockOutlined },
  directives: { maska },
})
export default class ButtonLoginGoogle extends Vue {}
</script>
