
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import LoginForm from '@/components/account/LoginForm.vue'

@Options({
  components: { LoginForm },
  directives: { maska },
})
export default class Login extends Vue {}
